// import '../styles/globals.css';
// import { InterFont } from '@/styles/fonts';
// import { ThemeProvider } from '@/styles/ThemeProvider';
// import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.min.css';
// import NextTopLoader from 'nextjs-toploader';
// import config from '@/lib/config/site';
// import { SpeedInsights } from '@vercel/speed-insights/next';
// import { Analytics } from '@vercel/analytics/react';

// const RootLayout = ({ children }) => {
//   return (
//     <html suppressHydrationWarning lang="en" className={`${InterFont.variable}`}>
//       <body className="bg-gradient-to-br from-green-400 via-teal-500 to-blue-600 h-screen text-red-900 mx-2">
//       {/* <body className="bg-gradient-to-br from-blue-900 via-green-500 to-red-400 h-screen text-red-900"> */}
//         <ThemeProvider
//           attribute="class"
//           defaultTheme="light"
//           enableSystem
//           disableTransitionOnChange
//         >
//           <NextTopLoader color={config.loading_bar_color} />
//           {children}
//         </ThemeProvider>
//         <SpeedInsights />
//         <Analytics />
//         <ToastContainer position="bottom-right" />
//       </body>
//     </html>
//   );
// };

// export default RootLayout;

'use client';

import '../styles/globals.css';
import { InterFont } from '@/styles/fonts';
import { ThemeProvider } from '@/styles/ThemeProvider';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import NextTopLoader from 'nextjs-toploader';
import config from '@/lib/config/site';
import { SpeedInsights } from '@vercel/speed-insights/next';
import { Analytics } from '@vercel/analytics/react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'; // Import React Query
import { useState } from 'react'; // Needed to initialize QueryClient

const RootLayout = ({ children }) => {
  // Step 2: Initialize QueryClient
  const [queryClient] = useState(() => new QueryClient());

  return (
    <html suppressHydrationWarning
lang="en"
className={`${InterFont.variable}`}>
      {/* <body className="bg-gradient-to-br from-green-400 via-teal-500 to-blue-600 h-screen text-red-900 mx-2"> */}
      <body className="mx-2">
        <ThemeProvider attribute="class"
defaultTheme="dark"
enableSystem
disableTransitionOnChange>
          {/* Step 3: Wrap the application in QueryClientProvider */}
          <QueryClientProvider client={queryClient}>
            <NextTopLoader color={config.loading_bar_color} />
            {children}
            <ToastContainer position="top-right" />
          </QueryClientProvider>
        </ThemeProvider>
        <SpeedInsights />
        <Analytics />
      </body>
    </html>
  );
};

export default RootLayout;
